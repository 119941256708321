<template>
    <main>
        <HeaderTab :title="$t('compte.mon_compte')" />
        <div id="content">
            <div class="container-fluid">
                <div class="box" id="menu_compte">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-12 col-sm-8 col-lg-4 mb-4 mb-lg-0">
                                <router-link
                                    class="item-menu"
                                    :to="{ name: 'switchLang' }"
                                >
                                    <span><span>
                                    <i v-b-tooltip.right :title="$t('global.language')">
                                        <font-awesome-icon :icon="['fal', 'language']" />
                                    </i></span>{{ $t('global.language') }}</span>
                                </router-link>
                            </div>

                            <div class="col-12 col-sm-8 col-lg-4 mb-4 mb-lg-0">
                                <router-link
                                    class="h-100 item-menu"
                                    :to="{ name: 'resetPassword' }"
                                >
                                    <span><span>
                                    <i v-b-tooltip.right :title="$t('compte.password')">
                                        <font-awesome-icon :icon="['fal', 'user-lock']" />
                                    </i></span>{{ $t('compte.password') }}</span>
                                </router-link>
                            </div>
                        </div>
                        </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'DisplaySettings',
    mixins: [],
    data: () => ({
    }),
    created() {
        this.init_component()
    },
    methods: {
        init_component() {
        },
    },
    components: {
        HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
    }
}
</script>